<script>
export default {
  metaInfo: {
    title: 'Мобильная версия ХаниМани', // 70 characters
    meta: [
      { name: 'description', content: 'Мобильная версия ХаниМани' }
    ]
  },
  components: {
    hm_zoom: require('@/components/hm_zoom.vue').default
  }
}
</script>

<template lang="pug">
.content
  h2.title.is-2 Мобильное приложение

  p Вы можете вносить транзакции с помощью мобильного приложения.
    p.has-text-centered
      .columns.is-gapless.is-mobile
        //- https://app.shotbot.io/#/project
        .column
        .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='../assets/mobile/list.png')
        .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='../assets/mobile/form.png')
        .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='../assets/mobile/stats.png')
        .column
  .subtitle.is-4 Как добавить ХаниМани на экран смартфона:
  ol
    li
      | Зайдите на <a href="https://app.hmbee.ru">https://app.hmbee.ru</a> через мобильный браузер.
      br
      img(src="./images/mobile1.png")
    li
      | Добавьте ХаниМани на экран «Домой».
      br
      img(src="./images/mobile2.png")
</template>
